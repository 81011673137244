import React, { Suspense, useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import './scss/style.scss';
import { jwtDecode } from 'jwt-decode';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Signin = React.lazy(() => import('./views/pages/signin/signin'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Forget = React.lazy(() => import('./views/pages/signin/forget'));
const Otp = React.lazy(() => import('./views/pages/signin/otp'));
const Reset = React.lazy(() => import('./views/pages/signin/reset'));

const App = () => {

  const access_token = localStorage.getItem("access_token");

  const parseDateToUnixTimestamp = (dateString) => {
    return new Date(dateString).getTime() / 1000; 
  };

  useEffect(()=>
  {
      
  if (access_token) {
    try {
      const decodedToken = jwtDecode(access_token); 
      const currentTime = Date.now() / 1000; 

      const expiringOn = parseDateToUnixTimestamp(decodedToken.expiring_on);
  
      if (expiringOn < currentTime) {
        localStorage.clear();
        window.location.href = '/login';
        return null;
      }
    } catch (error) {
      console.error('Error decoding token:', error);
  
    }
  } 
  },[access_token])

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />

           {access_token ? <Route path="*" name="Dashboard" element={<DefaultLayout />} />
              :
            <>
              <Route path="/" name="Home" element={<Navigate to="/login" />} />
              <Route path="/login" name="Login Page" element={<Signin />} />
              <Route path="/forget-password" name="Forget Page" element={<Forget />} />
              <Route path="/verify-otp" name="OTP Page" element={<Otp />} />
              <Route path="/reset-password" name="Reset Page" element={<Reset />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
