import { createStore } from 'redux'
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  sidebarShow: true,
  visible:{},
  name:'',
  selectedMode:'Dev',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
      case 'firstname':
        return {...state, ...rest}
      case 'mode':
        return {...state, ...rest}
      case 'visibledata':
        return {...state, ...rest}     
    default:
      return state
  }
}

const persistConfig = {
  key: 'root', // A key to define where in your storage your persisted data will be stored
  storage, // Use the storage mechanism you imported earlier
  // Add additional options as needed
};

const persistedReducer = persistReducer(persistConfig, changeState);

//const store = createStore(changeState)
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
export default store


